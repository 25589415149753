import { themeGet } from "@styled-system/theme-get"
import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { BlockContent } from "../components/content/block-content"
import { CollapseSection } from "../components/content/collapse-section"
import { Box } from "../components/core"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { buildImageObj } from "../lib/helpers"
import { imageUrlFor } from "../lib/image-url"
export const pageQuery = graphql`
  query GuideTemplateQuery($id: String!) {
    guide: sanityGuide(id: { eq: $id }) {
      id
      _updatedAt
      publishedAt(formatString: "MMMM, YYYY")
      title
      short_description
      _rawBody(resolveReferences: { maxDepth: 100 })
      _rawSources
      slug {
        current
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
    }
  }
`

export default function GuideTemplate({ data: { guide } }) {
  const { title, _updatedAt, publishedAt, short_description, mainImage, _rawBody, _rawSources } = guide

  return (
    <Layout>
      <SEO title={title} description={short_description} />

      <ImageWrapper>
        {mainImage && mainImage.asset && (
          <img
            style={{ width: "100%", height: "auto", zIndex: 1 }}
            src={imageUrlFor(buildImageObj(mainImage)).width(1500).height(750).fit("max").ignoreImageParams().url()}
          />
        )}
      </ImageWrapper>
      <Box textAlign="left" mt={["40px"]}>
        <Box
          as="time"
          display="block"
          fontSize={[2]}
          fontWeight={[1]}
          color="text.secondary"
          mb={["8px"]}
          datetime={_updatedAt}
        >
          {publishedAt}
        </Box>
        <Box as="h1">{title}</Box>
        <Box as="h2" mt={["8px"]} fontSize={[4]} lineHeight={[2]} fontWeight={[1]}>
          {short_description}
        </Box>
      </Box>
      <ContentWrapper>
        <MainWrapper>
          {/* <Box display={["block", "block", "none"]}>In this guide:</Box> */}
          <BlockContent blocks={_rawBody} serif />
          <CollapseSection title="Our Sources">
            <BlockContent blocks={_rawSources} />
          </CollapseSection>
        </MainWrapper>
        <SidebarWrapper>{/* <Box display={["none", "none", "block"]}>In this guide:</Box> */}</SidebarWrapper>
      </ContentWrapper>
    </Layout>
  )
}

const ImageWrapper = styled(Box).attrs(() => ({}))``

const ContentWrapper = styled(Box).attrs(() => ({
  paddingTop: ["32px", "64px"],
}))`
  @media (min-width: ${themeGet("breakpoints.md")}) {
    display: grid;
    grid-gap: 64px;
    grid-template-columns: auto 300px;
  }
`

const MainWrapper = styled(Box).attrs(() => ({}))``

const SidebarWrapper = styled(Box).attrs(() => ({}))``
